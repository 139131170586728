import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from "../../Common/PreviewCompatibleImage";

const ImageWithTextBlocks = ({ ImageWithTextBlocks }) => {
  return (
    <section className="position-relative">
      <div className="container py-7">
        <div className="row">
          {
            (ImageWithTextBlocks && ImageWithTextBlocks.length > 0) && (ImageWithTextBlocks.map((block, i) => (
              <section key={i} className="col col-12 col-md-6 col-lg-4 mb-3">
                <div className="card card-transparent">
                  {block && block.photo && (
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: block.photo,
                        alt: block.title,
                        className: 'card-img-top',
                      }}
                    />
                  )}
                  <div className="card-body text-center">
                    <h3 className="card-title text-uppercase text-primary mb-0">{block.title}</h3>
                    <p className="card-text">"{block.text}"</p>
                  </div>
                </div>
              </section>
            )))
          }
        </div>
      </div>
    </section>
  );
};

ImageWithTextBlocks.propTypes = {
  ImageWithTextBlocks: PropTypes.array,
};

export default ImageWithTextBlocks;
