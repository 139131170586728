import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import TemplateHeader from "../../components/TemplatePages/TemplateHeader/TemplateHeader";
import TitleSmBgAndDesc from "../../components/TemplatePages/TitleSmBgAndDesc/TitleSmBgAndDesc";
import StudentsSaying from "../../components/Pricing/StudentsSaying";
import ImageBlock from "../../components/TemplatePages/ImageBlock/ImageBlock";
import BlockCTA from "../../components/TemplatePages/BlockCTA/BlockCTA";
import TitleList from "../../components/TemplatePages/TitleList/TitleList";
import Content, {HTMLContent} from "../../components/Common/Content";
import ImageWithTextBlocks from "../../components/TemplatePages/ImageWithTextBlocks/ImageWithTextBlocks";

export const SalesPageLongTemplate = ({ frontmatter, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <React.Fragment>
      <TemplateHeader
        bgTitle={frontmatter.header.bgTitle}
        smTitle={frontmatter.header.smTitle}
        videoURL={frontmatter.header.videoURL}
        buttonLink={frontmatter.header.buttonLink}
      />
      <TitleSmBgAndDesc
        smTitle={frontmatter.block2.smTitle}
        bgTitle={frontmatter.block2.bgTitle}
        description={frontmatter.block2.description}
      />
      <ImageWithTextBlocks
        ImageWithTextBlocks={frontmatter.imageWithText}
      />
      {frontmatter.imageBlock1 &&
      <ImageBlock
        imageContainer={frontmatter.imageBlock1.imageContainer}
      />}
      <StudentsSaying
        title={frontmatter.studentsSaying.title}
        studentsComments={frontmatter.studentsSaying.studentsComments}
      />
      <TitleSmBgAndDesc
        smTitle={frontmatter.titleSmBgAndDesc1.smTitle}
        bgTitle={frontmatter.titleSmBgAndDesc1.bgTitle}
        description={frontmatter.titleSmBgAndDesc1.description}
      />
      {frontmatter.imageBlock2 &&
      <ImageBlock
        imageContainer={frontmatter.imageBlock2.imageContainer}
      />}
      <TitleList
        bgTitle={frontmatter.listBlock.bgTitle}
        smTitle={frontmatter.listBlock.smTitle}
        list={frontmatter.listBlock.items}
      />
      <TitleSmBgAndDesc
        smTitle={frontmatter.titleSmBgAndDesc2.smTitle}
        bgTitle={frontmatter.titleSmBgAndDesc2.bgTitle}
        description={frontmatter.titleSmBgAndDesc2.description}
      />
      {frontmatter.imageBlock3 &&
      <ImageBlock
        imageContainer={frontmatter.imageBlock3.imageContainer}
      />}
      <TitleSmBgAndDesc
        smTitle={frontmatter.twoTitles.smTitle}
        bgTitle={frontmatter.twoTitles.bgTitle}
      />
      {content &&
      <section className='container pb-7'>
        <PageContent content={content}/>
      </section>
      }
      {frontmatter.imageBlock4 &&
      <ImageBlock
        imageContainer={frontmatter.imageBlock4.imageContainer}
      />}
      <BlockCTA
        title={frontmatter.blockCTA.title}
        buttonLink={frontmatter.blockCTA.buttonLink}
      />
      <div className="mb-10"/>
    </React.Fragment>
  );
};

SalesPageLongTemplate.propTypes = {
  frontmatter: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const SalesPageLong = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout  title={frontmatter.header.smTitle || 'Info Page'}>
      <SalesPageLongTemplate contentComponent={HTMLContent} frontmatter={frontmatter} content={html} />
    </Layout>
  );
};

SalesPageLong.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SalesPageLong;

export const pageQuery = graphql`
  query SalesPageLong($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        header {
          smTitle
          bgTitle
          videoURL
          buttonLink {
            label
            path
          }
        }
        block2 {
          smTitle
          bgTitle
          description
        }
        imageWithText {
          title
          text
          photo {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        imageBlock1 {
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        studentsSaying {
          title
          studentsComments {
            name
            state
            comment
            videoURL
            photo {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        titleSmBgAndDesc1 {
          smTitle
          bgTitle
          description
        }
        imageBlock2 {
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        listBlock {
          smTitle
          bgTitle
          items
        }
        titleSmBgAndDesc2 {
          smTitle
          bgTitle
          description
        }
        imageBlock3 {
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        twoTitles {
          smTitle
          bgTitle
        }
        imageBlock4 {
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        blockCTA {
          title
          buttonLink{
            label
            path
          }
        } 
      }
    }
  }
`;
